@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body {
  background:#007cba;
  color:#fff;
}

v-tabs[horizontal] v-tab.my-predictions {
  min-width: 120px;
}

v-tabs {
    font-weight:600;
}

v-tab.is-active {
    color: #65beec;
}
v-tab {
    v-tab.is-active {
        color: #65beec;
    }
}
.vTabs--default v-tab.is-active::after {
    background-color: #65beec;
}
.vTabs--default v-tab:hover, .vTabs--default v-tab:focus {
    color: #65beec;
}

// -------------------------------------
// Settings
// -------------------------------------


$v-accordion-default-theme:         true !default;


// Accordion
// -------------------------------------

$v-accordion-spacing:               20px !default;

$v-pane-border-color:               #D8D8D8 !default;
$v-pane-expanded-border-color:      #2196F3 !default;
$v-pane-icon-color:                 #2196F3 !default;
$v-pane-hover-color:                #2196F3 !default;
$v-pane-disabled-opacity:           0.6 !default;

$v-pane-expand-animation-duration:  0.5s  !default;
$v-pane-hover-animation-duration:   0.25s !default;



/* Base styles
 ***************************************/


v-accordion {
    display: block;
}

v-pane {
    display: block;

    &.is-expanded {
        > v-pane-content {
            > div {
                display: visible;
            }
        }
    }

    &[disabled] > v-pane-header {
        opacity: $v-pane-disabled-opacity;
        pointer-events: none;
    }
}

v-pane-header {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    outline: none;

    &:focus {
        outline: none;
    }

    > div {
        display: block;
    }
}

v-pane-content {
    display: block;
    position: relative;
    overflow: hidden;
    max-height: 0px;

    > div {
        visibility: none;
    }
}

.ngdialog.ngdialog-theme-custom {
    padding-top: 125px;
    padding-bottom: 125px;

    .ngdialog-overlay {
        background: rgba(0,0,0,.5);
    }

    .ngdialog-content {
        background: #007cba;
        opacity: 1;
        padding: 1.8em 2em;
        width: 440px;
        -webkit-border-radius: 0;
        border-radius: 0;
    }

    .ngdialog-buttons {
        margin-top:20px;

        a {
            width:100%;
        }
    }

    .ngdialog-message h2 {
        font-size: 26px;
        font-family: 'Montserrat', 'Helvetica', Arial, sans-serif;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .ngdialog-message {
        font-size: 16px;
        line-height: 22px;
        font-family:'Lato';
        color:#fff;

        .team-name {
            font-size:1.5em;
        }

        .score-select {
            font-size:1.8em;
            margin:20px 0;
            height:50px;
        }

        .score-select a {
            font-weight:600;
            font-size: 24px;
            line-height: 18px;
            padding: 6px 10px;
        }

        .score {
            font-size:2.4em;
        }

        .flex-media-reverse > .flex-media-figure {
            margin:0 0 0 1em;
        }

        .flex-media-figure {
            margin:0 1em 0 0;
        }

        .ko-stage-help {
            margin-top:15px;
        }
    }

    .ngdialog-message ul {
        padding: 0 0 5px 35px;
    }

    .ngdialog-button.ngdialog-button-primary {
        background: #27ae60;
        -webkit-border-radius: 0;
        border-radius: 0;
    }

}

.flex-media {
    display: flex;
    align-items: flex-start;
}
.flex-media-reverse > .flex-media-figure {
    order:1;
}
.flex-media-body {
    flex: 1;
}

.row.match {

    font-size:1.2em;
    font-weight:400;

    .match-result {
        height:40px;
    }

    .result {
        .date {
            line-height:15px;
        }

        .date-day {
            font-weight:400;
            text-transform:uppercase;
            font-size:0.7em;
        }
        .date-time {
            font-size:0.9em;
        }
    }

    .flex-media {
        margin-top: 2px;
    }
    .flex-media-reverse > .flex-media-figure {
        margin:4px 0 0 1em;
    }
    .flex-media-figure {
        margin: 4px 1em 0 0;
    }

    a.btn {
        padding: 2px 12px;
    }

    .team-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
    }
    span.home-team {
        margin-right:10px;
    }
    span.away-team {
        margin-left:10px;
    }
    .flag-icon {
        display:inline-block;
        vertical-align: middle;
    }

    .result {
        font-weight:600;
    }

    @media (max-width: 768px) {
        .result {
            padding:0;
            min-width:50px;
            vertical-align:middle;
        }
    }

}

.row.results {

    .match-detail {
        font-size:0.8em;
    }

    v-tabs {
        background:#004466;
    }
    v-pages {
        background:#004466;
    }

    .vTabs--default {
        margin-bottom: 0;
    }
    .vTabs--default[horizontal] v-tab {
        padding: 5px 0;
    }

    .goals,
    .user-predictions {
        padding:5px 0
    }

    .goal a {
        color:#65beec;
    }
}

v-page.leaderboards-page {
    min-height:600px;
}

.ngdialog.ngdialog-theme-custom {

}

.btn-action {
    color: #ffffff;
    background-color: #23AAED;
    border-color: #23AAED;
}

.btn-action:hover,
.btn-action:focus,
.btn-action:active,
.btn-action.active,
.open .dropdown-toggle.btn-action {
    color: #ffffff;
    background-color: #088BCC;
    border-color: #23AAED;
}

.btn-action:active,
.btn-action.active,
.open .dropdown-toggle.btn-action {
    background-image: none;
}

.btn-action.disabled,
.btn-action[disabled],
fieldset[disabled] .btn-action,
.btn-action.disabled:hover,
.btn-action[disabled]:hover,
fieldset[disabled] .btn-action:hover,
.btn-action.disabled:focus,
.btn-action[disabled]:focus,
fieldset[disabled] .btn-action:focus,
.btn-action.disabled:active,
.btn-action[disabled]:active,
fieldset[disabled] .btn-action:active,
.btn-action.disabled.active,
.btn-action[disabled].active,
fieldset[disabled] .btn-action.active {
    background-color: #23AAED;
    border-color: #23AAED;
}

.btn-action .badge {
    color: #23AAED;
    background-color: #ffffff;
}

.btn-cancel {
    color: #ffffff;
    background-color: #DE615E;
    border-color: #DE615E;
}

.btn-cancel:hover,
.btn-cancel:focus,
.btn-cancel:active,
.btn-cancel.active,
.open .dropdown-toggle.btn-cancel {
    color: #ffffff;
    background-color: #D93936;
    border-color: #DE615E;
}

.btn-cancel:active,
.btn-cancel.active,
.open .dropdown-toggle.btn-cancel {
    background-image: none;
}

.btn-cancel.disabled,
.btn-cancel[disabled],
fieldset[disabled] .btn-cancel,
.btn-cancel.disabled:hover,
.btn-cancel[disabled]:hover,
fieldset[disabled] .btn-cancel:hover,
.btn-cancel.disabled:focus,
.btn-cancel[disabled]:focus,
fieldset[disabled] .btn-cancel:focus,
.btn-cancel.disabled:active,
.btn-cancel[disabled]:active,
fieldset[disabled] .btn-cancel:active,
.btn-cancel.disabled.active,
.btn-cancel[disabled].active,
fieldset[disabled] .btn-cancel.active {
    background-color: #DE615E;
    border-color: #DE615E;
}

.btn-cancel .badge {
    color: #DE615E;
    background-color: #ffffff;
}

.goals-admin {

    .match {

        input {
            color:#222;
        }

    }

}
